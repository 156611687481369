<template>
  <div class="d-flex align-items-center">
    <div v-if="icon !== undefined && icon !== null" :class="iconVariant !== undefined ? `text-${iconVariant}` : ''">
      <fi :icon="icon" class="mr-2" fixed-width :spin="shouldSpin" />
    </div>
    <div><slot /></div>
  </div>
</template>

<script>
export default {
  props: ['icon', 'icon-variant', 'spin'],
  computed: {
    shouldSpin () {
      return this.spin !== undefined && this.spin !== false
    }
  }
}
</script>
