<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
const defaultLayout = 'DefaultLayout'

export default {
  computed: {
    layout () {
      const applicableRoute = this.$route.matched.find(r => r.meta.layout)
      const layoutName = applicableRoute === undefined ? defaultLayout : applicableRoute.meta.layout
      return () => import('@/layouts/' + layoutName)
    }
  }
}
</script>
