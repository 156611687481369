<template>
  <app-layout>
    <router-view />
    <message-dialog />
  </app-layout>
</template>

<script>
import AppLayout from './layouts/AppLayout'
import MessageDialog from './components/MessageDialog'

export default {
  components: { AppLayout, MessageDialog }
}
</script>
