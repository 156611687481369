<template>
  <b-modal ref="dialog" :title="title">
    <div>
      {{message}}
    </div>
    <template slot="modal-footer">
      <b-btn v-for="(btn, index) in buttons" :key="index" :variant="btn.variant" @click="btn.clicked()">{{btn.text}}</b-btn>
    </template>
  </b-modal>
</template>

<script>
export default {
  data: () => ({
    title: '',
    message: '',
    buttons: []
  }),
  methods: {
    show (title, message, buttons) {
      this.title = title
      this.message = message
      this.buttons = [...buttons]

      const self = this
      return new Promise((resolve, reject) => {
        for (const btn of self.buttons) {
          btn.clicked = () => {
            self.$refs.dialog.hide()
            resolve(btn.result)
          }
        }

        self.$refs.dialog.show()
      })
    }
  }
}
</script>
