import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import IconText from '../components/IconText'

library.add(fas)

Vue.component('fi', FontAwesomeIcon)
Vue.component('icon-text', IconText)
