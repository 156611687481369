import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// /guest/s/n4i9w0q5/?ap=78:8a:20:86:c1:5a&id=16:0c:0a:95:92:5b&t=1616762821&url=http://connectivitycheck.gstatic.com%2fgenerate_204&ssid=SpinkNET+Guest

const routes = [
  {
    path: '/',
    name: 'default',
    component: () => import('../views/default')
  },
  {
    path: '/guest/s/:site',
    name: 'login',
    component: () => import('../views/login')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
